import { User, Menu } from '../components'

import { useIsMobile } from "../utils";

export const Header = () => {

    return (
        <header id="Header">
            <div className="cell1">
                <a href="/">
                    <img className="logo" src="../assets/images/logo.png" alt="logo" />
                </a>
            </div>
            <div className="cell2">
                <Menu />
            </div>
            { !useIsMobile() && (

                <div className="cell3">
                    <User/>   
                </div>
            )}
        </header>
    );
}