import { useContext } from "react";
import { Link } from "react-router-dom"

import { getSiteData } from "../utils";

import { StoreContext, StoreActions } from '../store'

import { Remote, Local, UIText } from '../config'

export const User = () => {

  const store = useContext(StoreContext)

  // const serverInfo = {
  //   uRL: process.env.REACT_APP_HOSTNAME + process.env.REACT_APP_DBASE + Remote.website,
  //   token: ""
  // }
  // getSiteData(store, serverInfo)

  const doLogout = () => {

    const serverInfo = {
      uRL: process.env.REACT_APP_HOSTNAME + process.env.REACT_APP_DBASE + Remote.website,
      token: ""
    }
    getSiteData(store, serverInfo)

    store.dispatch({
      type: StoreActions.logout,
      payload: {}
    })
  } 

  return (

    <>
      { store.state.user.access_token ? (

        <Link
          className="User-link" 
          to={"/"}
          onClick={() => doLogout()}
        >
          <button>{UIText.logOut}</button>
        </Link>        

      ) : (

        <>

          <Link
            className="User-link" 
            to={Local.register}
          >
            <button className="User-button">{UIText.register}</button>
          </Link>
          
          <Link
            className="User-link" 
            to={Local.login}
          >
            <button className="User-button">{UIText.login}</button>
          </Link>
        </>
      )}
    </>
  );
}
