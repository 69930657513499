import { useState, useEffect, useRef } from 'react'
import { Link, useSearchParams, useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form';

import { Breadcrumbs } from './Breadcrumbs'

import { fetchData } from "../utils";

import { Errors, UIText, Remote, Local } from '../config'

export const RegisterPassword = () => {
  
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const [feedback, setFeedback] = useState("")
  const [params, setParams] = useState({
    email: "",
    token: ""
  })
  const hasErrored = useRef(false)

  const {
    register,
    watch,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      Password: '',
      'Repeat Password': ''
    }
  });

  useEffect(() => {     

    setParams({ 
      first: searchParams.get('first'),
      last: searchParams.get('last'),
      email: searchParams.get('email'),
      token: searchParams.get('token')
    })

  }, [searchParams])

  const giveFeedback = (message) => {
    
    console.log('got message', message)
    if (message?.status === 'OK' ) {

      //console.log('in here')
      hasErrored.current = false
      
      setFeedback(UIText.registerPasswordFeedbackOk)
      setTimeout(() => {
        reset();
        setFeedback("")
        navigate("/login")
      }, 6000)

    } else if (hasErrored.current) {

      //console.log('ever here?')
      // oh dear we've tried twice - abort...
      setFeedback(UIText.registerPasswordFeedbackError)

      // reset error flag
      hasErrored.current = false
      
      setTimeout(() => {
        reset();
        setFeedback("")
      }, 6000)

    } else {

      //console.log('but why here?')

      if ( message?.data ) {
        
        // try one more time - wait a second, just in case
        setTimeout(() => {          
          sendRegistration(message?.data)
          // errored!
          hasErrored.current = true
        }, 1000)
      }
    }
  }

  const sendRegistration = (data) => {

    //console.log('sending email', data, process.env.REACT_APP_HOSTNAME + process.env.REACT_APP_DBASE + Remote.messages)

    const thisData = {
      First: params.first,
      Last: params.last,
      Email: params.email,
      Token: params.token,
      Password: data.Password
    }

    //console.log('send data', thisData)

    const fetchOptions = {
      method: 'POST',
      headers: {
        "Content-Type": 'application/json'
      },
      body: JSON.stringify(thisData)
    }

    const fetchParams = {
      url: process.env.REACT_APP_HOSTNAME + process.env.REACT_APP_DBASE + Remote.registerPassword,
      options: fetchOptions,
      cb: giveFeedback
    }

    fetchData(fetchParams)
  } 

  const onSubmit = async (data) => {    

    //console.log('submit data', data)
    sendRegistration(data)
  }

  return (

    <div id="Content">
      <h1>{UIText.registerPassword}</h1>
      <Breadcrumbs title={UIText.registerPassword} />
      
      <div className="plain">

        {params.email && params.token ? 
          ( 
            <>     
              { feedback ? 
                (
                  <p>{feedback}</p>
                ) : (
                  <>
                    <p>{UIText.registerPasswordDetails}</p>
                    <div id="registerForm">
                      <form onSubmit={handleSubmit(onSubmit)}>
                        
                        <label 
                          htmlFor={UIText.registerPasswordForm.passwordFirst}
                        >
                          {UIText.registerPasswordForm.passwordFirst}
                        </label>
                        <input 
                          type="password" 
                          name={UIText.registerPasswordForm.passwordFirst} 
                          {...register(UIText.registerPasswordForm.passwordFirst, { 
                            required: { 
                              value: true,
                              message: Errors.registerPasswordForm.passwordFirst
                            },
                          })}
                        />
                        {errors[UIText.registerPasswordForm.passwordFirst] && 
                          <p>{errors[UIText.registerPasswordForm.passwordFirst].message}</p>
                        }
                        
                        <label 
                          htmlFor={UIText.registerPasswordForm.passwordSecond}
                        >
                          {UIText.registerPasswordForm.passwordSecond}
                        </label>
                        <input 
                          type="password" 
                          name={UIText.registerPasswordForm.passwordSecond} 
                          {
                            ...register(UIText.registerPasswordForm.passwordSecond, 
                            { 
                              required: { 
                                value: true,
                                message: Errors.registerPasswordForm.passwordFirst
                              },
                              validate: (val) => {
                                if (watch(UIText.registerPasswordForm.passwordFirst) !== val) {
                                  return Errors.registerPasswordForm.passwordMatch;
                                }
                              }, 
                            })
                          }
                        />
                        {errors[UIText.registerPasswordForm.passwordSecond] && 
                          <p>{errors[UIText.registerPasswordForm.passwordSecond].message}</p>
                        }
                                        
                        <div className="submit">
                          <input
                            style={{
                              padding: '10px 14px'
                            }} 
                            type="submit" 
                          />
                        </div>
                      </form>
                    </div>
                  </>
              )} 
            </>    
          ): 
          (
            
              <p>
                { `Please `}
                <Link
                  className="User-link" 
                  to={Local.register}
                >
                  register
                </Link>
                { ` first.`}
              </p>
          )}      
      </div>     
    </div>
  );
}