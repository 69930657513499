import { fetchData } from "./fetch";
import { StoreActions } from '../store'

import { DBase } from '../config'

const getSiteHierarchy = (data) => {
    let tree = {};
    const childOf = {};
    data.forEach((item) => {
        if ( item.status === DBase.status.published ) {
            const { id, parent_id } = item;
            childOf[id] = childOf[id] || [];
            item.children = childOf[id];
            parent_id ? (childOf[parent_id] = childOf[parent_id] || []).push(item) : tree = { ...item };
        }
    });
    return tree;
};

export const getSiteData = (store, serverInfo) => { 
    
    let fetchParams = {
        url: serverInfo.uRL,
        cb: (data) => { 
            //console.log('got data', data)
            if ( data?.data.length ) {
                const site = getSiteHierarchy(data.data)
                store.dispatch({
                    type: StoreActions.setSiteSections,
                    payload: site
                })
            } 
        }
    }    
    
    if ( serverInfo.token ) {

        fetchParams = { ...fetchParams, 
            options: { 
                headers: {
                    Authorization: `Bearer ${serverInfo.token}`
                }
            }
        }
    }
    //console.log('fetching', fetchParams)
    fetchData(fetchParams)
}