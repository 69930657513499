import { createContext } from 'react'

export const StoreContext = createContext()

export class StoreActions {
  static login = 'user/login'
  static logout = 'user/logout'
  static setSiteSections = 'site/set site sections'
  static setSiteNeedsRouting = 'site/set site needs routing'
  static setBreadcrumbs = 'breadcrumbs/set breadcrumbs'
}

const userReducer = (state, action) => {
  switch (action.type) {
    case StoreActions.login:      
      const user = action.payload  
      //console.log('got login', user)
      return user
    case StoreActions.logout:
      return {}
    default:
      return state
  }
}

const siteReducer = (state, action) => {
  let site = {}
  switch (action.type) {
    case StoreActions.setSiteSections:
      //console.log('got new site', action.payload)
      site = { 
        updated: Date.now(),
        needsRouting: true,
        ...action.payload
      }
      //console.log('fetched site', site, state)     
      return site
    case StoreActions.setSiteNeedsRouting:
         
      site = { 
        ...state,
        needsRouting: action.payload
      }
      return site
    default:
      return state
  }
}

const breadcrumbsReducer = (state, action) => {
  //console.log('in breadcrumbs', action)
  switch (action.type) {
    case StoreActions.setBreadcrumbs:
      const breadcrumbs = action.payload
      return breadcrumbs
    default:
      return state
  }
}

export const initialState = {
  user: {},
  site: {
    updated: 0,
    needsRouting: false
  },
  breadcrumbs: {},
}

const combineReducers = reducers => {
  return (state = {}, action) => {
    const newState = {}
    for (let key in reducers) {
      newState[key] = reducers[key](state[key], action)
    }
    return newState
  }
}

export const rootReducer = combineReducers({
  user: userReducer,
  site: siteReducer,
  breadcrumbs: breadcrumbsReducer,
})
