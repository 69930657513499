import { useContext } from "react";

import { StoreContext } from '../store'


export const Breadcrumbs = (props) => {

    const { title } = props

    const store = useContext(StoreContext)

    return (
        <div id="breadcrumb">
            <div
              dangerouslySetInnerHTML={{
                __html: store.state.breadcrumbs[title],
              }}
            />
        </div>
    );
}