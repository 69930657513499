import { useContext } from "react";

import { StoreContext } from '../store'

import { User } from '../components'
import { UIText } from '../config'

export const Footer = () => {

    const store = useContext(StoreContext)

    return (
        <footer id="Footer">

            { !store.state.user.access_token && (    

                <div className="register">
                    <div className="cell1">
                        <h2>{UIText.registerHeading}</h2>
                        <p>
                            {UIText.registerInfo}
                        </p>
                        
                    </div>

                    <div className="cell2">
                        <User />  
                    </div>
                </div> 
            )}

            <div className="contact">
                <h3>{UIText.feedbackHeading}</h3>
                <p>For further information or to offer feedback on Storytelling Connects<span className="superscript">&reg;</span>, please <a href="/contact">get in contact</a>.</p>
            </div>
            <div className="copyright">

                <div className="cell1">                    
                    <p>© 2022 Storytelling Connects<span className="superscript">&reg;</span>. All Rights Reserved</p>
                </div>
                <div className="cell2">
                    <a href="/">
                        <img className="footer-logo" src="../assets/images/logo.png" alt="logo" />
                    </a>
                </div>
            </div>
        </footer>
    );
}