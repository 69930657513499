import { useState, useRef } from 'react'
import { useForm } from 'react-hook-form';

import { Breadcrumbs } from './Breadcrumbs'

import { fetchData } from "../utils";

import { Errors, UIText, Remote } from '../config'

export const Contact = () => {

  const [feedback, setFeedback] = useState("")
  const hasErrored = useRef(false)

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      Name: '',
      Email: '',
      Subject: '',
      Message: ''
    }
  });

  const giveFeedback = (message) => {
    
    //console.log('got message', message, hasErrored.current)
    if (message?.status === 'OK' ) {

      //console.log('in here')
      hasErrored.current = false
      
      setFeedback(UIText.contactFeedbackOk)
      setTimeout(() => {
        reset();
        setFeedback("")
      }, 6000)

    } else if (hasErrored.current) {

      //console.log('ever here?')
      // oh dear we've tried twice - abort...
      setFeedback(UIText.contactFeedbackError)

      // reset error flag
      hasErrored.current = false
      
      setTimeout(() => {
        reset();
        setFeedback("")
      }, 6000)

    } else {

      //console.log('but why here?')

      if( message?.data ) {
        
        // try one more time - wait a second, just in case
        setTimeout(() => {          
          sendEmail(message?.data)
          // errored!
          hasErrored.current = true
        }, 1000)
      }
    }
  }

  const sendEmail = (data) => {

    //console.log('sending email', data, process.env.REACT_APP_HOSTNAME + process.env.REACT_APP_DBASE + Remote.messages)

    const fetchOptions = {
      method: 'POST',
      headers: {
        "Content-Type": 'application/json'
      },
      body: JSON.stringify(data)
    }

    const fetchParams = {
      url: process.env.REACT_APP_HOSTNAME + process.env.REACT_APP_DBASE + Remote.contact,
      options: fetchOptions,
      cb: giveFeedback
    }

    fetchData(fetchParams)
  } 

  const onSubmit = async (data) => {

    //console.log('submit data', data)
    sendEmail(data)
  }

  return (

    <div id="Content">
      <h1>{UIText.contact}</h1>
      <Breadcrumbs title={UIText.contact} />
      <div className="plain">
        { feedback ? (
          <p>{feedback}</p>
        ) : (
          <>
            <p>{UIText.contactViaEmail}</p>
            <p>
                {UIText.siteContact.name} <br />
                {UIText.siteContact.email}
            </p>

            <p>{UIText.contactViaForm}</p>
            <div id="contactForm">
              <form onSubmit={handleSubmit(onSubmit)}>
                
                <label htmlFor={UIText.contactForm.name}>
                  {UIText.contactForm.name}
                </label>
                <input type="text" name={UIText.contactForm.name} {...register(UIText.contactForm.name, { required: true })} />
                {errors[UIText.contactForm.name] && <p>{Errors.contactForm.name}</p>}
                
                <label htmlFor={UIText.contactForm.email}>
                  {UIText.contactForm.email}
                </label>
                <input type="text" name={UIText.contactForm.email} {...register(UIText.contactForm.email, { required: true })} />
                {errors[UIText.contactForm.email] && <p>{Errors.contactForm.email}</p>}

                <label htmlFor={UIText.contactForm.subject}>
                  {UIText.contactForm.subject}
                </label>            
                <input type="text" name={UIText.contactForm.subject} {...register(UIText.contactForm.subject, { required: true })} />
                {errors[UIText.contactForm.subject] && <p>{Errors.contactForm.subject}</p>}

                <label htmlFor={UIText.contactForm.message}>
                  {UIText.contactForm.message} 
                </label>
                <textarea rows="4" name={UIText.contactForm.message} {...register(UIText.contactForm.message, { required: true })} />
                {errors[UIText.contactForm.message] && <p>{Errors.contactForm.message}</p>}
                
                <div className="submit">
                  <input
                    style={{
                      padding: '10px 14px'
                    }} 
                    type="submit" 
                  />
                </div>
              </form>
            </div>
          </>
        )}        
      </div>
    </div>
  );
}