export const DBase = {
    role: {
        facilitator: '400b7640-cb49-4b4c-96e6-76f1eef18432',
        participant: '5c606bdc-3e15-4c2d-ab6f-070755a445fa',
        webAdmin: '6b2c5a7f-74c1-4d31-acae-e6244ff58cb6'
    },
    user: {
        first: 'first_name',
        last: 'last_name',
        email: 'email',
        password: 'password',
    },
    status: {
        draft: 'draft',
        published: 'published',
        archived: 'archived'
    }
}