import { useState, useRef } from 'react'
import { useNavigate } from "react-router-dom";
import { useForm } from 'react-hook-form';

import { Breadcrumbs } from './Breadcrumbs'

import { fetchData } from "../utils";

import { Errors, UIText, Remote } from '../config'

export const Register = () => {

  const navigate = useNavigate()
  const [feedback, setFeedback] = useState("")
  const hasErrored = useRef(false)

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      Firstname: '',
      Lastname: '',
      Email: ''
    }
  });

  const giveFeedback = (message) => {
    
    //console.log('got message', message, hasErrored.current)
    if (message?.status === 'OK' ) {

      //console.log('in here')
      hasErrored.current = false
      
      setFeedback(UIText.registerFeedbackOk)
      setTimeout(() => {
        reset();
        setFeedback("")
        navigate("/")
      }, 6000)

    } else if (hasErrored.current) {

      //console.log('ever here?')
      // oh dear we've tried twice - abort...
      setFeedback(UIText.registerFeedbackError)

      // reset error flag
      hasErrored.current = false
      
      setTimeout(() => {
        reset();
        setFeedback("")
      }, 6000)

    } else {

      //console.log('but why here?')

      if( message?.data ) {
        
        // try one more time - wait a second, just in case
        setTimeout(() => {          
          sendRegistration(message?.data)
          // errored!
          hasErrored.current = true
        }, 1000)
      }
    }
  }

  const sendRegistration = (data) => {

    //console.log('sending email', data, process.env.REACT_APP_HOSTNAME + process.env.REACT_APP_DBASE + Remote.messages)

    const fetchOptions = {
      method: 'POST',
      headers: {
        "Content-Type": 'application/json'
      },
      body: JSON.stringify(data)
    }

    const fetchParams = {
      url: process.env.REACT_APP_HOSTNAME + process.env.REACT_APP_DBASE + Remote.register,
      options: fetchOptions,
      cb: giveFeedback
    }

    fetchData(fetchParams)
  } 

  const onSubmit = async (data) => {

    //console.log('submit data', data)
    sendRegistration(data)
  }

  return (

    <div id="Content">
      <h1>{UIText.register}</h1>
      <Breadcrumbs title={UIText.register} />
      <div className="plain">
        { feedback ? (
          <p>{feedback}</p>
        ) : (
          <>
            <p>{UIText.registerDetails}</p>
            <div id="registerForm">
              <form onSubmit={handleSubmit(onSubmit)}>
                
                <label 
                  htmlFor={UIText.registerForm.first}
                >
                  {UIText.registerForm.first}
                </label>
                <input 
                  type="text" 
                  name={UIText.registerForm.first}                   
                  {
                    ...register(UIText.registerForm.first, 
                    { 
                      required: { 
                        value: true,
                        message: Errors.registerForm.first
                      }
                    })
                  }
                />
                {errors[UIText.registerForm.first] && <p>{errors[UIText.registerForm.first].message}</p>}

                <label 
                  htmlFor={UIText.registerForm.last}
                >
                  {UIText.registerForm.last}
                </label>
                <input 
                  type="text" 
                  name={UIText.registerForm.last}                   
                  {
                    ...register(UIText.registerForm.last, 
                    { 
                      required: { 
                        value: true,
                        message: Errors.registerForm.last
                      }
                    })
                  }
                />
                {errors[UIText.registerForm.last] && <p>{errors[UIText.registerForm.last].message}</p>}
                
                <label 
                  htmlFor={UIText.registerForm.email}
                >
                  {UIText.registerForm.email}
                </label>
                <input 
                  type="text" 
                  name={UIText.registerForm.email} 
                  {
                    ...register(UIText.registerForm.email, 
                    { 
                      required: { 
                        value: true,
                        message: Errors.registerForm.required
                      },
                      validate: (val) => {
                        if (!val.includes('@')) {
                          return Errors.registerForm.email;
                        }
                      }, 
                    })
                  }
                />
                {errors[UIText.registerForm.email] && <p>{errors[UIText.registerForm.email].message}</p>}
                                
                <div className="submit">
                  <input
                    style={{
                      padding: '10px 14px'
                    }} 
                    type="submit" 
                  />
                </div>
              </form>
            </div>
          </>
        )}        
      </div>
    </div>
  );
}