import { useEffect, useReducer, useMemo } from "react";


import { Remote } from './config'
import { getSiteData } from "./utils";

import { Router } from './Router'
import { Header, Footer } from './components'

import { StoreContext, rootReducer, initialState } from './store'

export const App = () => {

  const [state, dispatch] = useReducer(rootReducer, initialState)

  const store = useMemo(() => {
    return { state: state, dispatch: dispatch }
  }, [state, dispatch])


  useEffect(() => {

    if (!(store.state.site.updated)) {
      // initial fetch
      const serverInfo = {
        uRL: process.env.REACT_APP_HOSTNAME + process.env.REACT_APP_DBASE + Remote.website,
        token: ""
      }
      getSiteData(store, serverInfo)
    }

  }, [store])

  return (
    <StoreContext.Provider value={store}>
      <Header />
      <main>
        <Router />
      </main>
      <Footer />    
    </StoreContext.Provider>
  );
}