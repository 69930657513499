import { useState, useEffect, useContext } from "react";
import { Route, Routes } from "react-router"

import { Content, Login, Register, Contact, RegisterPassword } from './components'

import { StoreContext, StoreActions } from './store'

import { Local, UIText } from './config'

export const Router = () => {
  
  const store = useContext(StoreContext)

  const [routes, setRoutes] = useState([])
  
  useEffect(() => {

    const site = store.state.site

    //console.log('got site', site)

    const breadcrumbs = {}

    if(site.needsRouting) {
          
      if( site.hasOwnProperty('id') ) {

        const routes = []
        // the first (childless) one is home
        routes.push(
          <Route
              key={site.title}
              path={"/"}
              element={
                <Content
                  type={site.type}
                  content={site.content}
                />
              }
          />
        )
        site.children.forEach((section, index) => {

          breadcrumbs[section.title] = `<h4>Home | <strong>${section.title}</strong></h4>`
          
          routes.push(
            <Route
              key={index}
              path={section.title}
              element={
                <Content
                  title={section.title}
                  type={section.type}
                  content={section.content}
                />
              }
            />
          )
          section.children.forEach((subSection, thisIndex) => { 

            breadcrumbs[subSection.title] = `<h4>Home | ${section.title} | <strong>${subSection.title}</strong></h4>`

            routes.push(
              <Route
                    key={index + " " + thisIndex}
                    path={`${section.title}/${subSection.title}`}   
                    element={
                      <Content
                        title={subSection.title}
                        type={section.type}
                        content={subSection.content}
                      />
                    }
              />
            )
          })
        })

        // now push some local routes for logging in etc...

        breadcrumbs[UIText.login] = `<h4>Home | <strong>${UIText.login}</strong></h4>`
        routes.push(
          <Route
            key={Local.login}
            path={Local.login}   
            element={
              <Login />
            }
          />
        )

        breadcrumbs[UIText.register] = `<h4>Home | <strong>${UIText.register}<strong></h4>`
        routes.push(
          <Route
            key={Local.register}
            path={Local.register}   
            element={
              <Register />
            }
          />
        )

        breadcrumbs[UIText.registerPassword] = `<h4>Home | <strong>${UIText.registerPassword}<strong></h4>`
        routes.push(
          <Route
            key={Local.registerPassword}
            path={Local.registerPassword}   
            element={
              <RegisterPassword />
            }
          />
        )

        breadcrumbs[UIText.contact] = `<h4>Home | <strong>${UIText.contact}</strong></h4>`
        routes.push(
          <Route
            key={Local.contact}
            path={Local.contact}   
            element={
              <Contact />
            }
          />
        )

        store.dispatch({
          type: StoreActions.setSiteNeedsRouting,
          payload: false
        })

        //console.log('breadcrumbs', breadcrumbs)
        store.dispatch({
          type: StoreActions.setBreadcrumbs,
          payload: breadcrumbs
        })        

        setRoutes(routes)
      }
    }

}, [store, routes])

  return (
    <Routes>
      {routes}
    </Routes>
  );
}