import { useEffect, useContext } from "react";

import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'

import { Breadcrumbs } from './Breadcrumbs'
import { Remote, maxSiteAge } from "../config";

import { StoreContext } from '../store'

import { getSiteData } from "../utils";

export const Content = (props) => {
  
  const { title, type, content } = props
  const store = useContext(StoreContext)

  useEffect(() => {

    const now = Date.now()

    //console.log('aged?', store.state.site.updated, now)

    if (now - store.state.site.updated > maxSiteAge) {
      
      const serverInfo = {
        uRL: process.env.REACT_APP_HOSTNAME + process.env.REACT_APP_DBASE + Remote.website,
        token: ""
      }
      getSiteData(store, serverInfo)
      //console.log('aged!', store.state.site.updated)
    }

  }, [store, content])

  return (
    <div id="Content">
      { title && 
        <>
          <h1>{title}</h1> 
          <Breadcrumbs title={title} />
        </>
      }       
      { type === 'markdown' ? (
          
            <ReactMarkdown 
              children={content}
              remarkPlugins={[remarkGfm]}
            />
        )
        : ( type === 'HTML' ? (
            <div
              dangerouslySetInnerHTML={{
                __html: content,
              }} 
            />
        ) 
        : (
          <div className="oneColumn">
            <p>Unknown type</p>
          </div>
        ))
      }
    </div>
  );
}