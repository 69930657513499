import { useState, useContext } from 'react'
import { useNavigate } from "react-router-dom";
import { useForm } from 'react-hook-form';

import { StoreActions, StoreContext } from '../store'

import { Breadcrumbs } from './Breadcrumbs'

import { fetchData, getSiteData } from "../utils";

import { Errors, UIText, Remote } from '../config'

export const Login = () => {

  const store = useContext(StoreContext)
  const [feedback, setFeedback] = useState("")
  const navigate = useNavigate()

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      Email: '',
      Password: '',
    }
  });

  const giveFeedback = (message) => {
    
    //console.log('got message from server', message)
    if (message?.data?.access_token) {

      setFeedback(UIText.loginFeedbackOk)
      
      store.dispatch({
        type: StoreActions.login,
        payload: message.data
      })   

      const serverInfo = {
        uRL: process.env.REACT_APP_HOSTNAME + process.env.REACT_APP_DBASE + Remote.websiteSecure,
        token: message.data.access_token
      }
      getSiteData(store, serverInfo)

      setTimeout(() => {

        reset();
        setFeedback("")     
             
        navigate("/Resources")
        
      }, 2000)

    } else {

      // oh dear we've tried twice - abort...
      setFeedback(UIText.loginFeedbackError)

      setTimeout(() => {
        reset();
        setFeedback("")
        navigate("/")
      }, 6000)

    } 
  }

  const sendLogin = (data) => {

    const thisData = {
      email: data.Email,
      password: data.Password
    }

    const fetchOptions = {
      method: 'POST',
      headers: {
        "Content-Type": 'application/json'
      },
      body: JSON.stringify(thisData)
    }

    const fetchParams = {
      url: process.env.REACT_APP_HOSTNAME + process.env.REACT_APP_DBASE + Remote.login,
      options: fetchOptions,
      cb: giveFeedback
    }

    fetchData(fetchParams)
  } 

  const onSubmit = async (data) => {
    sendLogin(data)
  }

  return (

    <div id="Content">
      <h1>{UIText.login}</h1>
      <Breadcrumbs title={UIText.login} />
      <div className="plain">
        { feedback ? (
          <p>{feedback}</p>
        ) : (
          <>
            <div id="loginForm">
              <form onSubmit={handleSubmit(onSubmit)}>                              
                
                <label 
                  htmlFor={UIText.loginForm.email}
                >
                  {UIText.loginForm.email}
                </label>
                <input 
                  type="text" 
                  name={UIText.loginForm.email} 
                  {
                    ...register(UIText.loginForm.email, 
                    { 
                      required: { 
                        value: true,
                        message: Errors.loginForm.required
                      },
                      validate: (val) => {
                        if (!val.includes('@')) {
                          return Errors.loginForm.email;
                        }
                      }, 
                    })
                  }
                />
                {errors[UIText.loginForm.email] && <p>{errors[UIText.loginForm.email].message}</p>}

                <label 
                  htmlFor={UIText.loginForm.password}
                >
                  {UIText.loginForm.password}
                </label>
                <input 
                  type="password" 
                  name={UIText.loginForm.password} 
                  {...register(UIText.loginForm.password, { 
                    required: { 
                      value: true,
                      message: Errors.loginForm.password
                    },
                  })}
                />
                {errors[UIText.loginForm.password] && 
                  <p>{errors[UIText.loginForm.password].message}</p>
                }
                                
                <div className="submit">
                  <input
                    style={{
                      padding: '10px 14px'
                    }} 
                    type="submit" 
                  />
                </div>
              </form>
            </div>
          </>
        )}        
      </div>
    </div>
  );
}