export const Remote = {
    login: '/auth/login',
    website: '/items/web',
    websiteSecure: '/items/websecure',
    users: '/users',
    me: '/users/me',
    register: '/register',
    registerPassword: '/register-password',
    contact: '/contact'
}

export const Local = {
    register: '/register',
    registerPassword: '/register-password',
    login: '/login',
    contact: '/contact',
}