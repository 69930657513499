import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom"

import { StoreContext } from '../store'

import { Local, UIText } from '../config'

import { useIsMobile } from "../utils";

export const Menu = () => {

    const store = useContext(StoreContext)

    const [isMenuOpen, setIsMenuOpen] = useState(false)
    const [menu, setMenu] = useState([])
    const [expandIndex, setExpandIndex] = useState(0)

    //console.log('mobile?', useIsMobile())

    useEffect(() => {     

        const site = store.state.site
        const menuSections = []     
        if( site.hasOwnProperty('id') ) {
            // home
            menuSections.push(
                <Link
                    className="Menu-link"
                    key={site.id}
                    to={"/"}
                    onClick={() => {
                        setActiveMenu(site.title)
                    }}
                >
                    {site.title}
                </Link>
            )
            // everything else
            site.children.forEach((section, index) => {
                const thisMenu = []         
                thisMenu.push(
                    <Link
                        className="Menu-link"
                        key={index}
                        to={section.title}
                        onClick={() => {
                            setActiveMenu(section.title)
                            setExpandIndex(index + 1)
                        }}

                    >
                        {section.title}
                    </Link>
                )
                const subMenus = section.children.map((subSection, thisIndex) => {   
                    return (
                        <Link
                            className="Menu-link" 
                            key={subSection.id}
                            to={`${section.title}/${subSection.title}`}  
                            onClick={() => {
                                setActiveMenu(`${section.title}/${subSection.title}`)
                            }} 
                        >
                            {subSection.title}
                        </Link>
                    )
                })
                thisMenu.push(subMenus)
                menuSections.push(thisMenu)
            })
            const thisMenu = []
            thisMenu.push(
                <Link
                    className="Menu-link"
                    key={Local.contact}
                    to={Local.contact}
                    onClick={() => {
                        setActiveMenu(UIText.contact)
                    }}
                >
                    {UIText.contact}
                </Link>
            )
            menuSections.push(thisMenu)
            setMenu(menuSections)
            // console.log('menu stuff', menuSections)
        }

    }, [store.state.site])

    const setActiveMenu = (title) => {
        //console.log('menu', title)
    }

    return (
        <div id="Menu">
            { useIsMobile() ? (

                <>
                    <p
                        key="menu"
                        id="Menu-burger"
                        onClick={() => {
                            const mainOpen = !isMenuOpen
                            setIsMenuOpen(mainOpen)
                            if (mainOpen ) {
                                setExpandIndex(0)
                            }
                        }}
                    >
                        ≡
                    </p>

                    <nav 
                        id="Mobile-main-nav"
                        className={isMenuOpen ? "open" : "close"}
                    >
                        <div 
                                key="menu-close"
                                id="mobile-menu-close"
                                onClick={() => {
                                    setIsMenuOpen(false)
                                }}
                        >
                            ×
                        
                        </div>
                        {menu.map((section, index) => {

//console.log('menu', section, index)

                            if( index ) {

                                return expandIndex === index && section[1]?.length ? (
                                    <div
                                        key={'menu' + index}
                                        id="mobile-menu-items"
                                    >  
                                        {section[0]}
                                        <div
                                            id="Menu-expander"
                                            key={'menu-expander' + index}
                                            onClick={() => {
                                                setIsMenuOpen(false)
                                            }}
                                        >
                                            {section[1]}
                                        </div>

                                        <hr width="100%"/>
                                    </div>
                                ) : (
                                    <div
                                        key={'menu' + index}
                                    >
                                        <div 
                                            onClick={() => {
                                                setIsMenuOpen(false)
                                            }}
                                        > 
                                            {section[0]}
                                        </div>

                                        <hr width="100%"/>
                                    </div>
                                )

                            } else {

                                // home
                                return (
                                    <div
                                        key="menu-home"
                                        onClick={() => {
                                            setIsMenuOpen(false)
                                        }}
                                    >
                                        {section}

                                        <hr width="100%"/>
                                    </div>
                                )
                            }

                        })}

                    </nav>
                </>

            ) : (
                <>
                    {menu.map((section, index) => {

                        //console.log('menu', section, index)

                        if( index ) {

                            return expandIndex === index && section[1]?.length ? (
                                <div key={'menu' + index}>  
                                    {section[0]}
                                    <div
                                        id="Menu-expander"
                                        key={'menu-expander' + index}
                                    >
                                        {section[1]}
                                    </div>
                                </div>
                            ) : (
                                <div key={'menu' + index}> 
                                    {section[0]}
                                </div>
                            )

                        } else {

                            // home
                            return section

                        }
                        
                    })}
                </>
            )}
        </div>
    );
}